<template>
  <el-dialog
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :destroy-on-close="true"
    :show-close="false"
    width="45%"
    :title="`${isEdit ? '编辑' : '添加'}学习内容`"
    :visible.sync="isShow"
  >
    <div class="all-input">
      <div class="item-input">
        <div class="label"><span class="red">*</span>标题：</div>
                <el-input v-model="formData.title" class="mini"></el-input>

      </div>
      <div class="item-input">
        <div class="label"><span class="red">*</span>说明：</div>
                <el-input v-model="formData.remarks" class="mini"></el-input>

      </div>
      <div class="item-input">
        <div class="label"><span class="red">*</span>分类：</div>
        <el-select class="mini" v-model="formData.type_id" placeholder="请选择">
          <el-option
            v-for="item in options"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </div>
      <div class="item-input">
        <div class="label"><span class="red">*</span>学习组长：</div>
        <div class="mini map" @click="selectPe">{{nickname}}</div>
        <!-- <el-select class="mini" v-model="formData.head_id" placeholder="请选择">
          <el-option
            v-for="item in options"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select> -->
      </div>
      <div class="item-input">
        <div class="label"><span class="red">*</span>分值(分)：</div>
        <el-input-number
          class="mini"
          v-model="formData.score"
          controls-position="right"
          :min="1"
        ></el-input-number>
      </div>
      <div class="item-input">
        <div class="label"><span class="red">*</span>时长(分钟)：</div>
        <el-input-number
          class="mini"
          v-model="formData.time"
          controls-position="right"
          :min="1"
        ></el-input-number>
      </div>
      <div class="item-input">
        <TEditor v-model="formData.content"></TEditor>
      </div>
      <div style="margin-top: 10px" class="item-input file">
        <div class="label"><span class="red">*</span>上传文件：</div>
        <el-upload
          ref="upload"
          action="https://jsonplaceholder.typicode.com/posts/"
          :file-list="fileList"
          :auto-upload="false"
          :on-change="getFile"
          :on-remove="delFile"
          :http-request="submitUpload"
        >
          <el-button slot="trigger" size="small" type="primary"
            >选取文件</el-button
          >
          <el-button
            style="margin-left: 10px"
            size="small"
            type="success"
            :loading='isLoad'
            @click="upload"
            >上传到服务器</el-button
          >
          <div slot="tip" class="el-upload__tip">
            只能上传png/jpg/pdf/doc/docx/xlsx/ceb/xls/gif/mp3/mp4文件
          </div>
        </el-upload>
      </div>
    </div>

    <select-table :choice='onCallbackPe' ref='addS'></select-table>
    <div slot="footer" class="dialog-footer">
      <el-button type="primary"  @click="submit"
        >确 定</el-button
      >
      <el-button @click="close">取 消</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { addLearnContent, updLearnContent, getLCTSelect } from '@/api/safety'
import { uploadFile } from '@/api/common'
import { debounce } from '@/utils/util'
import selectTable from '@/components/selectTable'
export default {
  name: 'addCheck',
  props: {
    loadChange: Function,
    data: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  components: {
    selectTable,
  },
  data() {
    return {
      isShow: true, // 是否打开弹窗
      isLoad: false,
      options: [],
      fileList: [],
      formData: {
        type_id: '', // 类型
        time: '', // 时长
        title: '', // 标题
        head_id: '', // 组长
        score: '', // 分值
        content: '', // 内容
        files: '', // 文件
        remarks: '', // 说明
      },
      toastData: {
        type_id: '请选择分类', // 类型
        time: '请输入时长', // 时长
        title: '请输入标题', // 标题
        head_id: '请选择组长', // 组长
        score: '请输入分值', // 分值
        remarks: '请输入说明', // 说明
      },
      isEdit: false,
      files: [], // 上传文件列表
      fileIndex: 0, // 文件当前索引
      isSave: false, // 是否保存
      backup: [],
      value: '', // 富文本
      list: [],
      obj: {},
      nickname: '',

    }
  },
  created() {
    this._loadSelect()

    if (this.data.files) {
      this.isEdit = true
      this.data.files.forEach(s => {
        this.fileList.push({
          name: s.url,
          url: s.url,
        })
        this.files.push({
          status: 'success',
          name: s.url,
          url: s.url,
        })
        this.list.push(s.url)
      })
      this.nickname = this.data.nickname
      this.formData = this.data
      this.formData.files = this.list.join(',')
    }
  },
  methods: {
    _loadSelect: function () {
      getLCTSelect().then((res) => {
        this.options = res.result
      })
    },
    selectPe: function() {
      this.$refs.addS.open()
    },
    onCallbackPe: function(res) {
      this.formData.head_id = res.id
      this.nickname = res.nickname
    },
    upload: debounce(async function () {
      this.list = []
      this.files = this.files.map(s => {
        if (s.raw) {
          s = s.raw
        }
        return s
      })

      this.backup = []
      if (this.files.length) this.isLoad = true
      for await (const f of this.files) {
        this.submitUpload(f)
      }
    }, 1000),
    submitUpload: function(f) {
      return new Promise(resolve => {
        if (f.status && f.status === 'success') {
          this.backup.push(f)
          this.list.pust(f)
          this.fileIndex++
          if (this.fileIndex === this.files.length) {
            this.isSave = true
            this.fileList = this.backup
            this.isLoad = false
          } else this.isSave = false
          resolve('success')
        } else {
          uploadFile(f).then(res => {
            const { result } = res
            this.backup.push({
              name: f.name,
              url: result.filePath,
            })
            this.list.push({
              name: f.name,
              url: result.filePath,
            })
            this.fileIndex++
            if (this.fileIndex === this.files.length) {
              this.isSave = true
              this.fileList = this.backup
              this.isLoad = false
            } else this.isSave = false
            resolve('success')
          })
        }
      })
    },

    // 获取文件
    getFile: function(file) {
      const uid = this.files.find(s => {
        return s.uid === file.raw.uid
      })
      if (!uid) {
        this.files.push(file)
        this.isSave = false
      }
      this.fileIndex = 0
    },

    // 获取删除后的文件列表
    delFile: function(file, files) {
      this.files = files
      const name = file.name

      this.list.forEach((s, i) => {
        if (s === name || s.name === name) {
          this.list.splice(i, 1)
        }
      })
    },

    close: function (isLoad) {
      this.$emit('close')
    },

    submit: function () {
      for (const key in this.toastData) {
        if (!this.formData[key]) {
          this.$toast(this.toastData[key])
          return false
        }
      }
      if (!this.list.length) {
        this.$toast('请上传图片')
        return false
      }

      const list = []
      this.list.forEach(s => {
        list.push(s.url || s)
      })
      this.formData.files = list.join(',')

      // console.log(this.formData, '这是表单')
      if (!this.isEdit) this.saveContent()
      else this.editContent()
    },

    saveContent: function () {
      addLearnContent(this.formData).then((res) => {
        this.$toast(res.message)
        if (res.status === 200) {
          this.close(true)
          this.loadChange && this.loadChange()
        }
      })
    },

    editContent: function () {
      updLearnContent(this.formData).then((res) => {
        this.$toast(res.message)
        if (res.status === 200) {
          this.close(true)
          this.loadChange && this.loadChange()
        }
      })
    },
  },
}
</script>

<style lang="stylus" scoped>
.all-input {
  justify-content: space-between;

  .file {
    align-items: flex-start;
  }
}
</style>
