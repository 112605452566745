/* eslint-disable no-undef */
<template>
  <div>
    <div v-if="!showType">
      <SEARCH :searchChange='onCallbackSearch' :list="searchArr"></SEARCH>
      <TABS :tabChange="onCallbackTab"  :list="tabsArr"></TABS>
      <TABLE :buttonChange='onCallbackButton' :isShowSele="false" :list="tableArr" :data="dataArr"></TABLE>
      <PAGE :pageChange='onCallbackPage' :limitChange='onCallbackLimit' :count="count"></PAGE>
    </div>
    <div v-else-if='showType === "total"'>
      <NAVBAR :backChange='onCallbackBack' title="学习记录统计"></NAVBAR>
      <total :id='totalId'></total>
    </div>

    <add-content @close='_loadData' :data='data' v-if="isShow" ref="addC"></add-content>
  </div>
</template>

<script>
import SEARCH from '@/components/search' // 顶部搜索
import TABS from '@/components/tabs' // 横向 button
import TABLE from '@/components/table' // 列表
import PAGE from '@/components/page' // 分页
import NAVBAR from '@/components/navbar' // 导航
import total from './components/total'
import addContent from './components/addContent'
import { getLearnContent, delLearnContent, getLCTSelect, getLearnContentDetail } from '@/api/safety'
// eslint-disable-next-line no-unused-vars
import saveAs from 'file-saver'
import '@/js/jquery.wordexport'
export default {
  name: 'SafetyLearnContent',
  components: {
    SEARCH,
    TABS,
    TABLE,
    PAGE,
    NAVBAR,
    total,
    addContent,
  },
  data() {
    return {
      searchArr: [
        {
          name: '分类',
          type: 'picker',
          options: [],
          key: 'type',
          label: 'name',
          value: 'id',
        },
        {
          name: '标题',
          type: 'input',
          options: [],
          key: 'title',
        },
      ], // 搜索列表
      tabsArr: [
        {
          type: 'primary',
          name: '添加内容',
          func: 'openAddContent',
        },
      ], // 标签列表
      tableArr: [
        {
          prop: 'title',
          label: '标题',
          width: '200',
        },
        {
          prop: 'type_id',
          label: '分类',
          width: '200',
        },
        {
          prop: 'remarks',
          label: '说明',
          width: '200',
        },
        {
          prop: 'head_id',
          label: '组长',
          width: '200',
        },
        {
          prop: 'time',
          label: '时长(分钟)',
          width: '200',
        },
        {
          prop: 'score',
          label: '分值',
          width: '200',
        },
        {
          prop: 'create_at',
          label: '创建时间',
          width: '200',
        },
        {
          prop: '',
          label: '操作',
          width: '',
          type: 'but',
        },
      ], // 表头列表
      dataArr: [], // 数据列表
      butnArr: [
        {
          type: '',
          name: '统计',
          func: 'openTotal',
        },
        {
          type: '',
          name: '编辑',
          func: 'editAddContent',
        },
        {
          type: 'danger',
          name: '删除',
          func: 'removeContent',
        },
      ], // 数据-操作列表
      count: 0, // 总页数
      formData: {
        page: 1,
        limit: 10,
        title: '',
        type_id: '',
      },
      totalId: '', // 统计id
      showType: '', // 是否显示统计
      value: '',
      html: '',
      isShow: false,
      data: {},
    }
  },
  created() {},
  mounted() {
    !this.showType && this._loadData()
    this._loadSelect()
  },
  methods: {
    _loadSelect: function() {
      getLCTSelect().then(res => {
        const { result } = res
        result.unshift({ id: '', name: '全部' })
        this.searchArr[0].options = result
      })
    },
    _loadData: function () {
      this.isShow = false
      this.dataArr = []
      this.data = {}
      getLearnContent(this.formData).then((res) => {
        const { result } = res

        this.count = result.count
        result.data.forEach(item => {
          item.buttons = JSON.parse(JSON.stringify(this.butnArr))
        })
        this.dataArr = result.data
      })
    },

    openAddContent: function() {
      this.isShow = true
    },

    editAddContent: function({ id }) {
      getLearnContentDetail(id).then(res => {
        this.data = res.result
        this.isShow = true
      })
    },

    // 删除学习内容
    removeContent: function({ id }) {
      delLearnContent(id).then(res => {
        this.$toast(res.message)
        this._loadData()
      })
    },

    // 打开统计
    openTotal: function(item) {
      this.totalId = String(item.id)
      this.$store.commit('SET_BC', false)
      this.showType = 'total'
    },

    // 关闭统计
    onCallbackBack: function() {
      this.$store.commit('SET_BC', true)
      this.showType = ''
      this._loadData()
    },

    // 收到搜索回调
    onCallbackSearch: function(data) {
      const { type, title } = data
      this.formData.title = title
      this.formData.type_id = type
      this._loadData()
    },

    // 收到 table内方法 回调
    onCallbackButton: function(item, func) {
      this[func](item)
    },

    // 收到 tabs 回调
    onCallbackTab: function ({ item, index }) {
      this[item.func]()
    },

    // 收到改变页码回调
    onCallbackPage: function(page) {
      this.formData.page = page
      this._loadData()
    },
    // 收到改变一页数量回调
    onCallbackLimit: function(limit) {
      this.formData.limit = limit
      this._loadData()
    },
  },
}
</script>

<style lang="stylus" scoped></style>
