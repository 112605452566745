<template>
  <div id="detail-z">
    <TABLE :switchChange='onCallbackSwitch' :buttonChange='onCallbackButton' :isShowSele="false" :list="tableArr" :data="dataArr"></TABLE>
    <PAGE :pageChange='onCallbackPage' :limitChange='onCallbackLimit' :count="count"></PAGE>
  </div>
</template>

<script>
import TABLE from '@/components/table' // 列表
import PAGE from '@/components/page' // 分页
import { updDictConfigStatus, delDictConfigStatus } from '@/api/system'
import { getLearnContentTotal } from '@/api/safety'
export default {
  name: 'ContentTotal',
  components: {
    TABLE,
    PAGE,
  },
  data() {
    return {
      tableArr: [
        {
          prop: 'user_id',
          label: '姓名',
          width: '133',
        },
        {
          prop: 'study_time',
          label: '已学习时长',
          width: '300',
        },
        {
          prop: 'score',
          label: '得分',
          width: '300',
        },
        {
          prop: 's_id',
          label: '标题',
          width: '300',
        },
        {
          prop: 'menu_name',
          label: '总时长',
          width: '300',
        },
        {
          prop: 'status',
          label: '总分',
          width: '300',
        },
      ], // 表头列表
      dataArr: [], // 数据列表
      count: 0, // 总页数
      formData: {
        page: 1,
        limit: 10,
        id: '',
      },
    }
  },
  props: {
    id: {
      type: String,
      default() {
        return ''
      },
    },
  },
  created() {},
  mounted() {
    this._loadData()
  },
  methods: {
    _loadData: function () {
      this.formData.id = this.id
      getLearnContentTotal(this.formData).then((res) => {
        const { result } = res

        this.count = result.count
        result.data.forEach(item => {
          item.buttons = JSON.parse(JSON.stringify(this.butnArr))
        })
        this.dataArr = result.data
      })
    },

    // 状态 开关
    changeStatus: function(item) {
      const data = {
        id: item.id,
        status: item.status === 1 ? 0 : 1,
      }
      updDictConfigStatus(data).then(res => {
        this.$toast(res.message)
        this._loadData()
      })
    },

    // 删除围栏
    removeDictConfigStatus: function({ id }) {
      delDictConfigStatus(id).then(res => {
        this.$toast(res.message)
        this._loadData()
      })
    },

    // 收到搜索回调
    onCallbackSearch: function(data) {
      const { fenceName, fenceProject } = data
      this.formData.name = fenceName
      this.formData.p_id = fenceProject
      this._loadData()
    },

    // 收到 table内方法 回调
    onCallbackButton: function(item, func) {
      this[func](item)
    },
    // 收到 表格内 switch 方法回调
    onCallbackSwitch: function(item, func) {
      this[func](item)
    },

    // 收到改变页码回调
    onCallbackPage: function(page) {
      this.formData.page = page
      this._loadData()
    },
    // 收到改变一页数量回调
    onCallbackLimit: function(limit) {
      this.formData.limit = limit
      this._loadData()
    },
  },
}
</script>

<style lang="stylus" scoped>

</style>
