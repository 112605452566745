import { PublicAxios } from '@/utils/publicAxios'
const $ = new PublicAxios()

// --------------------------------------------
// 系统API接口
// --------------------------------------------

/**
 * 获取字典列表
 * @param {Object} data 详看接口文档
 * Boolean => isLoad 是否需要加载框 默认为false
 */
export const getDict = async data => {
  return $.get({
    url: 'admin/dictionary/deplyList',
    data,
    isLoad: true,
  })
}

/**
 * 修改字典 状态
 * @param {Object} data 详看接口文档
 */
export const updDictStatus = async data => {
  return $.get({
    url: 'admin/dictionary/deplyStatus',
    data,
  })
}

/**
 * 删除字典
 * @param {String} id 字典id
 */
export const delDict = async id => {
  return $.get({
    url: 'admin/dictionary/deplyDel',
    data: { id },
  })
}

/**
 * 获取字典配置列表
 * @param {Object} data 详看接口文档
 * Boolean => isLoad 是否需要加载框 默认为false
 */
export const getDictConfig = async data => {
  return $.get({
    url: 'admin/dictionary/configList',
    data,
    isLoad: true,
  })
}

/**
 * 修改配置 状态
 * @param {Object} data 详看接口文档
 */
export const updDictConfigStatus = async data => {
  return $.get({
    url: 'admin/dictionary/configStatus',
    data,
  })
}
/**
 * 删除配置列表
 * @param {String} id 字典id
 */
export const delDictConfigStatus = async id => {
  return $.get({
    url: 'admin/dictionary/configDel',
    data: { id },
  })
}

/**
 * 获取角色管理
 * @param {Object} data 详看接口文档
 * Boolean => isLoad 是否需要加载框 默认为false
 */
export const getRoleMan = async data => {
  return $.get({
    url: 'admin/AdminRole/roleList',
    data,
    isLoad: true,
  })
}

/**
 * 修改角色信息
 * @param {Object} data 详看接口文档
 */
export const updRoleMan = async data => {
  return $.post({
    url: 'admin/AdminRole/roleEdit',
    data,
  })
}

/**
 * 删除角色
 * @param {String} id 角色id
 */
export const delRoleMan = async id => {
  return $.get({
    url: 'admin/AdminRole/roleDele',
    data: { admin_role_id: id },
  })
}

/**
 * 获取管理员列表
 * @param {Object} data 详看接口文档
 * Boolean => isLoad 是否需要加载框 默认为false
 */
export const getAdmitList = async data => {
  return $.get({
    url: 'admin/AdminUser/userList',
    data,
    isLoad: true,
  })
}

/**
 * 修改管理员信息
 * @param {Object} data 详看接口文档
 */
export const updAdmin = async data => {
  return $.post({
    url: 'admin/AdminUser/userEdit',
    data,
  })
}

/**
 * 删除管理员
 * @param {String} id 管理员id
 */
export const delAdmin = async id => {
  return $.post({
    url: 'admin/AdminUser/userDele',
    data: { admin_user_id: id },
  })
}

/**
 * 获取权限规则
 * @param {Object} data 详看接口文档
 * Boolean => isLoad 是否需要加载框 默认为false
 */
export const getRules = async data => {
  return $.get({
    url: 'admin/AdminMenu/menuList',
    data,
    isLoad: true,
  })
}

/**
 * 修改权限状态
 * @param {Object} data 详看接口文档
 */
export const updRules = async data => {
  return $.post({
    url: 'admin/AdminMenu/menuDisable',
    data,
  })
}

/**
 * 修改权限
 * @param {Object} data 详看接口文档
 */
export const updRulesAuth = async data => {
  return $.post({
    url: 'admin/AdminMenu/menuUnauth',
    data,
  })
}

/**
 * 删除权限规则
 * @param {String} id 权限规则id
 */
export const delRules = async id => {
  return $.get({
    url: 'admin/AdminMenu/menuDele',
    data: { admin_menu_id: id },
  })
}
